import React from 'react';
import TenantAPI from 'api/tenantAPI';
import { Button, Page, PageHero, Input, RadioGroup, FormGroup } from '@punchcard/core';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const TenantDetailsPage = () => {
	const { tenantId } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const tenantIdNumber = Number(tenantId);


	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const methods = useForm<Tenant>({
		defaultValues: {
			name: '',
			isActive: true,
		}
	});

	React.useEffect(() => {
		async function getTenant() {
			const tenantData = await TenantAPI.getTenant(tenantId);
			methods.reset(tenantData);
		}
		if (tenantIdNumber && tenantIdNumber > 0) {
			getTenant();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenantId, tenantIdNumber]);

	return (
		<Page>
			<PageHero title={tenantId !== '0' ? 'Edit Network' : 'Add New Network'} />
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-4">
						<Controller
							control={methods.control}
							name="name"
							rules={{
								required: 'This is a required field'
							}}
							render={({ field, fieldState }) =>
								<FormGroup label={t('networks.network_name')} field={field} fieldState={fieldState}>
									<Input
										error={!!fieldState.error?.message}
										{...field}
										placeholder={t('networks.enter_network_name')}
									/>
								</FormGroup>
							}
						/>
					</div>
					<div className="col-lg-4">
						<Controller
							control={methods.control}
							name="isActive"
							render={({ field, fieldState }) =>
								<FormGroup label={t('users.status')} field={field} fieldState={fieldState}>
									<RadioGroup<boolean>
										value={field.value ?? true}
										onChange={field.onChange}
										options={[
											{
												label: t('active'),
												value: true
											},
											{
												label: t('inactive'),
												value: false
											}
										]}
									/>
								</FormGroup>
							}
						/>
					</div>
				</div>
				<Button
					className="btn-primary me-2"
					onClick={methods.handleSubmit(submitTenant)}
					loading={isLoading}
					disabled={isLoading}
				>
					{t('navigation.save')}
				</Button>
				<Button
					className="btn-outline-primary"
					onClick={onCancelClick}
				>
					{t('cancel')}
				</Button>

			</div>
		</Page>
	);


	function onCancelClick() {
		navigate('/admin/tenants');
	}

	function submitTenant(values: Tenant) {
		setIsLoading(true);

		TenantAPI.saveTenant(values)
			.then(() => {
				if (tenantIdNumber <= 0) {
					setIsLoading(false);
					// if on new Org page, redirect to Edit page, else redirect to list page
					toast.success(t('networks.successfully_added_network'));
					// history.push takes state object as a second argument.
					navigate('/admin/tenants');
				} else {
					toast.success(t('networks.network_updated'));
					navigate('/admin/tenants');
				}
			})
			.catch((_err: any) => {
				toast.error('Something went wrong!', {
					autoClose: 3000,
					theme: 'dark'
				});
				setIsLoading(false);
			});
	}
};

export default TenantDetailsPage;
