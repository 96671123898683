import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Page, FormGroup, Input, RadioGroup, LoadingScreen, ModalOverlay, Button, Select } from '@punchcard/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
import { useTranslation } from 'react-i18next';
import coreAPI from 'api/coreAPI';
import EntityHeader from '@punchcard/core/components/EntityHeader';
import TransferHistory from '@punchcard/core/components/Shared/TransferHistory';
import { IconEditUnfilled, IconWarning } from '@punchcard/core/icons';
import TransferModal from '@punchcard/core/components/Shared/TransferModal';

const SchoolUserPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [showRestPasswordModal, setShowResetPasswordModal] = React.useState<boolean>(false);
	const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
	const [hideUserEdit, setHideUserEdit] = React.useState<boolean>(false);
	const [transferHistory, setTransferHistory] = React.useState<UserTransferDTO[]>([]);
	const [schoolList, setSchoolList] = React.useState<AdminSchoolDTO[]>([]);
	const [showTransferModal, setShowTransferModal] = React.useState<boolean>(false);
	const [roleOptions, setRoleOptions] = React.useState<{ value: string, label: string }[]>([]);
	const methods = useForm<AdminSchoolUserDTO>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			userTypeCode: 'Student',
			isActive: true,
			schoolName: '',
			roleId: 0,
		}

	});
	const userId: number = parseInt(params.userId ?? '0');
	const schoolId: number = parseInt(params.schoolId ?? '0');
	const { handleSubmit, control, reset, formState, getValues } = methods;
	const pageTitle = userId === 0 ? t('users.add_user') : t('users.edit_user');
	const isActive = methods.watch('isActive');
	const isSSO = methods.watch('isSsoUser');
	const userType = methods.watch('userTypeCode');
	async function fetchData() {
		setLoading(true);
		const school = await adminAPI.school.getSchoolUser(userId, schoolId);
		const roles = await coreAPI.getRolesForCurrentUser(userId);
		if (roles && roles.length > 0) {
			const roleOptions = roles.map((role) => {
				return { value: role.id.toString(), label: role.name };
			});
			setRoleOptions(roleOptions);
		}
		setTransferHistory(school.transferHistory);
		setHideUserEdit(school.transferred);	// if transferred, hide the save button
		const schoolList = await adminAPI.school.getSchoolList();
		setSchoolList(schoolList);
		await reset(school);
		setLoading(false);
	}
	React.useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, reset]);

	const handleResetPassword = async () => {
		try {
			setShowSpinner(true);
			const user = await saveData();
			if (user) {
				const result = await adminAPI.users.postResetUserPassword(userId);
				if (result) {
					toast.success(t('users.an_email_has_been_sent', { email: getValues('email') }));
					setShowResetPasswordModal(false);
				} else {
					toast.error(t('users.failed_to_update_password'));
				}
			} else {
				toast.error(t('users.failed_to_update_password'));
			}
		} catch (error) {
			toast.error(t('users.failed_to_update_password'));

		}
		setShowSpinner(false);
	};
	const handleTransfer = async (studentId: number, newTenantId: number) => {
		const result = await adminAPI.users.postTransferStudent(studentId, newTenantId);
		if (result) {
			toast.success('Student transferred');
			await fetchData();
			setShowTransferModal(false);

		} else {
			toast.error('Failed to transfer student');
		}
	};
	const getUserName = () => {
		const data = getValues();
		if (data.firstName && data.lastName) {
			return `${data.firstName} ${data.lastName}'s`;
		} else if (data.firstName) {
			return `${data.firstName}'s`;
		} else if (data.lastName) {
			return `${data.lastName}'s`;
		} else {
			return 'the';
		}
	};
	const submitData = async () => {
		setLoading(true);
		const user = await saveData();
		if (user) {
			toast.success(t('users.user_saved'));
			navigate(-1);
		} else {
			toast.error(t('users.unable_to_save_user'));
		}
		setLoading(false);
	};
	const saveData = async () => {
		const data = getValues();
		const dto: UserDetailsDTO = {
			id: userId,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			userType: data.userTypeCode,
			isActive: data.isActive,
			roleIds: userType === 'Student' ? [] : [data.roleId],
			schoolId: schoolId,
			tenantId: 1,
		};
		const user = await coreAPI.postTenantUser(dto);
		return user;
	};
	if (loading) {
		return <LoadingScreen />;
	}
	return (
		<Page className="bg-light p-3 ps-5" title={pageTitle} >
			<FormProvider {...methods}>
				<div className="container-fluid">
					<div className="row">
						<div className=" col-lg-7">
							<EntityHeader title={pageTitle} parentRoute={`/schools/${schoolId}/users${location.search}`} saveEntity={handleSubmit(submitData)} isDirty={formState.isDirty} />
							<div className=" bg-white p-3 rounded-2 mt-3">
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="firstName"
											rules={{
												required: t('this_field_is_required'),
											}}
											render={({ field, fieldState }) =>
												<FormGroup label={t('users.first_name')} fieldState={fieldState} className="pb-2" required={true} aria-disabled={hideUserEdit}>
													<Input
														disabled={hideUserEdit}
														{...field}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="lastName"
											rules={{
												required: t('this_field_is_required'),
											}}
											render={({ field, fieldState }) =>
												<FormGroup label={t('users.last_name')} fieldState={fieldState} className="pb-2" required={true} aria-disabled={hideUserEdit}>
													<Input
														disabled={hideUserEdit}
														{...field}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="email"
											rules={{
												required: t('this_field_is_required'),
											}}
											render={({ field, fieldState }) =>
												<FormGroup label={t('users.email')} fieldState={fieldState} className="pb-2" required={true} aria-disabled={hideUserEdit}>
													<Input
														disabled={isSSO || hideUserEdit}
														{...field}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								{hideUserEdit === false && isSSO === false && isActive === true && userId !== 0 &&
									<div className="row">
										<div className="col">
											<div className="mb-3 pb-2">
												<p className="form-label">Reset password</p>
												<p>{t('users.click_reset_email_password')}</p>
												<Button className="btn-outline-secondary" onClick={() => setShowResetPasswordModal(true)}>{t('users.reset_password')}</Button>
											</div>
										</div>
									</div>
								}
								{hideUserEdit === false && isSSO && userId !== 0 &&
									<div className="row">
										<div className="col">
											<div className="mb-3 pb-2">
												<p className="form-label">{t('users.reset_password')}</p>
												<p>{t('users.this_user_is_sso_enforced')}</p>
											</div>
										</div>
									</div>
								}
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											disabled={hideUserEdit}
											name="userTypeCode"
											render={({ field }) =>
												<FormGroup label={t('users.user_type')} className="pb-2" aria-disabled={hideUserEdit}>
													<RadioGroup
														options={[
															{ label: 'School Staff', value: 'Teacher' },
															{ label: 'Student', value: 'Student' }
														]}
														value={field.value}
														onChange={field.onChange}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								{userType !== 'Student' && <div className="row">
									<div className="col">
										<Controller
											control={control}
											name="roleId"
											render={({ field }) =>
												<FormGroup label={t('users.role_name')} className="pb-2">
													<Select
														{...field}
														isDisabled={hideUserEdit}
														value={field.value.toString()}
														options={roleOptions}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								}
								{hideUserEdit === false && userId !== 0 &&
									<div className="row">
										<div className="col">
											<Controller
												control={control}
												name="schoolName"
												render={({ field }) =>
													<FormGroup label={t('schools.students_school')} className="pb-2" >
														<React.Fragment>
															<p className="mb-0">{field.value}
																<Button onClick={() => setShowTransferModal(true)} className="btn-ghost-primary">
																	<IconEditUnfilled />
																</Button></p>
															<div className="text-warning-800 d-flex align-items-start" >
																<IconWarning />
																<span className="ms-1">{t('transfer.transferring_will_cause_chaos')}</span>
															</div>
														</React.Fragment>
													</FormGroup>
												}
											/>
										</div>
									</div>
								}
								{hideUserEdit === false &&
									<div className="row">
										<div className="col">
											<Controller
												control={control}
												name="isActive"
												render={({ field }) =>
													<FormGroup label={t('users.status')} className="pb-2" >
														<RadioGroup
															options={[
																{ label: 'Active', value: true },
																{ label: 'Inactive', value: false }
															]}
															value={field.value ?? true}
															onChange={field.onChange}
														/>
													</FormGroup>
												}
											/>
										</div>
									</div>
								}
								{transferHistory.length > 0 &&
									<div className="row">
										<TransferHistory transferHistory={transferHistory} />
									</div>
								}
							</div>
						</div>
					</div>
				</div>
				<ModalOverlay
					isOpen={showRestPasswordModal}
					modalSize="md"
					loading={showSpinner}
					onRequestClose={() => setShowResetPasswordModal(false)}
					headerChildren={t('users.reset_password')}
					confirmButtonChildren={t('users.reset')}
					cancelButtonChildren={t('cancel')}
					hideCloseButton={true}
					confirmButtonAction={() => {
						handleResetPassword();
					}}
					cancelButtonAction={() => {
						setShowResetPasswordModal(false);
					}}
				>
					{t('users.are_you_sure_you_want_to_reset_password', { fullName: getUserName(), emailAddress: getValues('email') })}
				</ModalOverlay>
				<TransferModal
					showTransferModal={showTransferModal}
					closeModal={() => setShowTransferModal(false)}
					currentStudent={methods.getValues()}
					schoolList={schoolList}
					transferStudent={(studentId, newTenantId) => handleTransfer(studentId, newTenantId)}
				/>
			</FormProvider>
		</Page >
	);
};

export default SchoolUserPage;
