import React, { useEffect } from 'react';
import { Button, setStatusCellValue, filterByStatus, StatusFilterMethod, ActionIcon, DataTable, PageHero, Page } from '@punchcard/core';
import tenantAPI from 'api/tenantAPI';
import { useNavigate } from 'react-router-dom';
import { IconEdit, IconPerson } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';
const TenantListPage = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [tenants, setTenants] = React.useState<Tenant[]>([]);
	useEffect(() => {
		tenantAPI.getTenants()
			.then((tenants: Tenant[]) => setTenants(tenants));
	}, []);

	const tenantColumns = [
		{
			Header: 'Id ',
			accessor: 'id',
			filterable: true
		},
		{
			Header: 'Network',
			accessor: 'name',
			filterable: true
		},
		{
			Header: 'Status',
			accessor: 'isActive',
			Cell: setStatusCellValue,
			filterMethod: filterByStatus('isActive'),
			Filter: StatusFilterMethod
		},
		{
			Header: 'Actions',
			accessor: 'id',
			showClearButton: true,
			Cell: (row: any) => (
				<React.Fragment>
					<ActionIcon title="Manage Users" icon={IconPerson} onClick={() => navigate(`/admin/tenants/${row.value}/users`)} />
					<ActionIcon title="Edit network details" icon={IconEdit} onClick={() => navigate(`/admin/tenants/${row.value}`)} />
				</React.Fragment>
			)
		}
	];
	return (
		<Page>
			<PageHero title="Networks">
				<Button className="btn-primary" to="/admin/tenants/0">
					{t('networks.add_network')}
				</Button>
			</PageHero>
			<div className="container-fluid">
				<DataTable
					data={tenants}
					columns={tenantColumns}
					filterable={true}
					noDataText="No networks found"
				/>
			</div>
		</Page>
	);
};

export default TenantListPage;