import axios from 'axios';

const course = {
	getCourseDocument: (code: string): Promise<File> => {
		return axios.get(`/admin/coursedocument/${code}`, {
			responseType: 'blob'
		}).then(res => res.data);
	},
	deleteCourseDocument: (code: string) => {
		return axios.delete(`/admin/coursedocument/${code}`, {
		}).then(res => res.data as boolean);
	},
	getCourseList: (): Promise<AdminCourseDTO[]> => {
		return axios.get('/admin/courselist', {
		}).then(res => res.data as AdminCourseDTO[]);
	},
	getCourse: (courseId: number): Promise<AdminCourseDTO> => {
		return axios.get(`/admin/course/${courseId}`, {
		}).then(res => res.data as AdminCourseDTO);
	},
	deleteCourse: (courseId: number): Promise<boolean> => {
		return axios.delete(`/admin/course/${courseId}`, {
		}).then(res => res.data as boolean);
	},
	postCourse: (course: AdminCourseDTO): Promise<AdminCourseDTO> => {
		return axios.post('/admin/course/', course)
			.then(res => res.data as AdminCourseDTO);
	},
	postCreateCourseId: (id: number): Promise<number> => {
		return axios.post('/admin/createcourseid/', id)
			.then(res => res.data as number);
	},
	postCreateCourseUnitId: (unit: AdminCourseAndUnitIdDTO) => {
		return axios.post('/admin/createcourseunitid/', unit)
			.then(res => res.data as AdminCourseAndUnitIdDTO);
	},
	postCreateCourseLessonId: (unit: AdminCourseAndUnitIdDTO) => {
		return axios.post('/admin/createcourselessonid/', unit)
			.then(res => res.data as AdminCourseAndUnitIdDTO);
	},
	postCreateCourseActivityId: (unit: AdminCourseAndUnitIdDTO) => {
		return axios.post('/admin/createcourseactivityid/', unit)
			.then(res => res.data as AdminCourseAndUnitIdDTO);
	},
	postCreateCourseAssessmentId: (unit: AdminCourseAndUnitIdDTO) => {
		return axios.post('/admin/createcourseassessmentid/', unit)
			.then(res => res.data as AdminCourseAndUnitIdDTO);
	},
	uploadCourseDocument(file: FileList, doc: AdminCourseDocumentDTO) {
		const formData = new FormData();
		formData.append('file', file[0]);
		formData.append('id', doc.id.toString());
		formData.append('courseId', doc.courseId.toString());
		formData.append('courseUnitId', doc.courseUnitId.toString());
		formData.append('documentName', doc.documentName ?? '');
		formData.append('versionNumber', (doc.versionNumber ?? '').toString());
		formData.append('documentTypeId', doc.documentTypeId.toString());

		return axios.post('/admin/uploadcoursedocument', formData, {})
			.then(res => res.data as AdminCourseDocumentDTO);
	},

};
const school = {
	getSchoolList: (): Promise<AdminSchoolDTO[]> => {
		return axios.get('/admin/schoollist', {
		}).then(res => res.data as AdminSchoolDTO[]);
	},
	postCreateSchoolId: (id: number): Promise<number> => {
		return axios.post('/admin/createschoolid/', id)
			.then(res => res.data as number);
	},
	getSchool: (schoolId: number): Promise<AdminSchoolDTO> => {
		return axios.get(`/admin/school/${schoolId}`, {
		}).then(res => res.data as AdminSchoolDTO);
	},
	getSchoolUser: (userId: number, schoolId: number): Promise<AdminSchoolUserDTO> => {
		return axios.get(`/admin/schooluser/${userId}/${schoolId}`, {
		}).then(res => res.data as AdminSchoolUserDTO);
	},
	getTenantUser: (userId: number): Promise<AdminSchoolUserDTO> => {
		return axios.get(`/admin/tenantuser/${userId}`, {
		}).then(res => res.data as AdminSchoolUserDTO);
	},
	getSchoolUserList: (schoolId: number): Promise<AdminSchoolAndUserDTO> => {
		return axios.get(`/admin/schooluserlist/${schoolId}`, {
		}).then(res => res.data as AdminSchoolAndUserDTO);
	},
	getTenantUserList: (tenantId: number): Promise<AdminSchoolAndUserDTO> => {
		return axios.get(`/admin/tenantuserlist/${tenantId}`, {
		}).then(res => res.data as AdminSchoolAndUserDTO);
	},
	deleteSchool: (schoolId: number): Promise<boolean> => {
		return axios.delete(`/admin/school/${schoolId}`, {
		}).then(res => res.data as boolean);
	},
	postSchool: (school: AdminSchoolDTO): Promise<AdminSchoolDTO> => {
		return axios.post('/admin/school/', school)
			.then(res => res.data as AdminSchoolDTO);
	},
	postSchoolUser: (user: AdminSchoolUserDTO): Promise<AdminSchoolUserDTO> => {
		return axios.post('/admin/schooluser/', user)
			.then(res => res.data as AdminSchoolUserDTO);
	},
};
const schoolTeacher = {
	getSchoolTeacherList: (schoolId: number): Promise<AdminSchoolTeacherDTO[]> => {
		return axios.get(`/admin/schoolteachers/${schoolId}`, {
		}).then(res => res.data as AdminSchoolTeacherDTO[]);
	},
	postSchoolTeacher: (schoolTeacher: AdminSchoolTeacherDTO): Promise<AdminSchoolTeacherDTO> => {
		return axios.post('/admin/schoolteacher/', schoolTeacher)
			.then(res => res.data as AdminSchoolTeacherDTO);
	},
	deleteSchoolTeacher: (schoolTeacherId: number): Promise<boolean> => {
		return axios.delete(`/admin/schoolteacher/${schoolTeacherId}`, {
		}).then(res => res.data as boolean);
	},
};
const schoolCourse = {
	getSchoolCourseList: (schoolId: number): Promise<AdminSchoolCourseDTO[]> => {
		return axios.get(`/admin/schoolcourses/${schoolId}`, {
		}).then(res => res.data as AdminSchoolCourseDTO[]);
	},
	postSchoolCourse: (courseId: number, schoolId: number[]): Promise<AdminCourseSchoolDTO> => {
		return axios.post('/admin/schoolcourse/', { courseId, schoolId })
			.then(res => res.data as AdminCourseSchoolDTO);
	},
	deleteSchoolCourse: (schoolCourseId: number): Promise<boolean> => {
		return axios.delete(`/admin/schoolcourse/${schoolCourseId}`, {
		}).then(res => res.data as boolean);
	},
	getSchoolsByCourse: (courseId: number): Promise<AdminCourseSchoolDTO> => {
		return axios.get(`/admin/course/${courseId}/schools`, {
		}).then(res => res.data as AdminCourseSchoolDTO);
	},
	getUnassignedSchoolsByCourse: (courseId: number): Promise<AdminSchoolDTO[]> => {
		return axios.get(`/admin/course/${courseId}/schools/unassigned`, {
		}).then(res => res.data as AdminSchoolDTO[]);
	},
};
const users = {
	postResetUserPassword: async (userId: number): Promise<boolean> => {
		const res = await axios.post('/admin/resetuserpassword', { id: userId });
		return res.data as boolean;
	},
	postTransferStudent: async (studentId: number, newSchoolId: number): Promise<boolean> => {
		const res = await axios.post('/admin/transferstudent', { studentId, newSchoolId });
		return res.data as boolean;
	}
};
const schoolAttributeSite = {
	getSchoolSiteList: async (schoolId: number): Promise<SchoolAttributeDTO> => {
		const res = await axios.get(`/admin/schools/${schoolId}/attributes/${AttributeType.Site}`, {});
		return res.data as SchoolAttributeDTO;
	},
	getSchoolSiteById: async (siteId: number, SchoolId: number): Promise<AttributeItemDTO> => {
		const res = await axios.get(`/admin/schools/${SchoolId}/attributes/${AttributeType.Site}/${siteId}`, {});
		return res.data as AttributeItemDTO;
	},
	postSchoolSite: async (siteId: number, SchoolId: number, siteName: string, siteDescription: string): Promise<SchoolAttributeDTO> => {
		const res = await axios.post(`/admin/schools/${SchoolId}/attributes/${AttributeType.Site}`, {
			attributeItemId: siteId,
			value: siteName,
			description: siteDescription,
		});
		return res.data as SchoolAttributeDTO;
	},
	deleteSchoolSite: async (siteId: number, SchoolId: number): Promise<boolean> => {
		const res = await axios.delete(`/admin/schools/${SchoolId}/attributes/${AttributeType.Site}/${siteId}`, {});
		return res.data as boolean;
	},
	getSiteUsersBySiteId: async (siteId: number, schoolId: number): Promise<AttributeUsersDTO> => {
		const res = await axios.get(`/admin/schools/${schoolId}/attributes/${AttributeType.Site}/${siteId}/users`, {});
		return res.data as AttributeUsersDTO;
	},
	getUnassignedUsersBySiteId: async (siteId: number, schoolId: number): Promise<UserDetailsBase[]> => {
		const res = await axios.get(`/admin/schools/${schoolId}/attributes/${AttributeType.Site}/${siteId}/unassigned-users`, {});
		return res.data as UserDetailsBase[];
	},
	addUsersToSite: async (siteId: number, schoolId: number, userIds: number[]): Promise<boolean> => {
		const res = await axios.post(`/admin/schools/${schoolId}/attributes/${AttributeType.Site}/${siteId}/users`, {
			userIds,
		});
		return res.data as boolean;
	},
	removeUserFromSite: async (siteId: number, schoolId: number, xrefUserId: number): Promise<boolean> => {
		const res = await axios.delete(`/admin/schools/${schoolId}/attributes/${AttributeType.Site}/${siteId}/users/${xrefUserId}`, {});
		return res.data as boolean;
	}
};

const adminAPI = {
	course,
	users,
	school,
	schoolTeacher,
	schoolCourse,
	schoolSites: schoolAttributeSite,
};

const enum AttributeType {
	Site = 'site',
}

export default adminAPI;